import { PageData } from '@wix/platform-editor-sdk';
import { TpaPageId } from '@wix/pricing-plans-router-utils';
import type { ControllerParams } from '@wix/yoshi-flow-editor';

type WixCodeApi = ControllerParams['flowAPI']['controllerConfig']['wixCodeApi'];
type AppParams = ControllerParams['flowAPI']['controllerConfig']['appParams'];

export interface PageInstallationInfo {
  tpaPageId: TpaPageId;
  pageData?: Partial<PageData>;
}

export const MPA_PAGES: PageInstallationInfo[] = [
  { tpaPageId: TpaPageId.PackagePicker },
  { tpaPageId: TpaPageId.Checkout },
  { tpaPageId: TpaPageId.ThankYou },
  { tpaPageId: TpaPageId.Paywall, pageData: { indexable: false } },
];

export const MPA_ON_ECOM_PAGES: PageInstallationInfo[] = [
  { tpaPageId: TpaPageId.PackagePicker },
  { tpaPageId: TpaPageId.PlanCustomization },
  { tpaPageId: TpaPageId.ThankYou, pageData: { title: 'Continue' } },
  { tpaPageId: TpaPageId.Paywall, pageData: { indexable: false } },
];

export async function isMultiPageApp(wixCodeApi: WixCodeApi, appParams: AppParams): Promise<boolean> {
  if (appParams.appRouters?.length !== 1) {
    return false;
  }
  const sections = await Promise.all(
    MPA_PAGES.map((page) =>
      wixCodeApi.site.isAppSectionInstalled({
        sectionId: page.tpaPageId,
        appDefinitionId: '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
      }),
    ),
  );
  return sections.every((section) => section);
}
